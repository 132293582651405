<template>
  <div class="page">
    <Breadcrumbs/>
    <div class="page-container block-container">
      <div class="column left-block large-block">
        <h1>Notice-and-Take-Down-procedure Hostingondemand</h1>
        <p>De Notice-and-Take-Down-procedure is bedoeld voor melders met een klacht over de inhoud van een website. De melder kan zich op basis van deze procedure richten tot de hostingprovider waarbij de website is ondergebracht, indien het niet mogelijk is gebleken de klacht (succesvol) bij de houder van de website neer te leggen. Dit kan zijn omdat de houder geen reactie geeft of geen actie wil ondernemen op de melding, maar ook indien er contactinformatie ontbreekt op de website.</p>
        <p>Middels de Notice-and-Take-Down-procedure is het voor Hostingondemand mogelijk om meldingen over illegale inhoud op een website van een van haar klanten in behandeling te nemen en hiervoor een eenduidige weg te volgen. Het is belangrijk voor de melder om te weten dat Hostingondemand niet op de stoel van de rechter kan zitten. Indien Hostingondemand actie onderneemt op een melding, houdt dit in dat er sprake is van onmiskenbare onrechtmatigheid. Dat wil zeggen dat het misbruik zo goed als in 1 oogopslag duidelijk moet zijn. In geen geval wordt gehandeld aan de hand van een vermoeden van de melder dan wel van Hostingondemand zelf.</p>
        <p>Het is dan ook van belang om bij het indienen van een verzoek via het <router-link to="/NTD-Formulier/">Notice-and-Take-Down-formulier</router-link> (hierna te noemen NTD-formulier), alle velden volledig en naar waarheid in te vullen zodat Hostingondemand de procedure zo goed en efficiënt mogelijk kan doorlopen. Daarnaast dient u te allen tijde eerst zelf de houder van de website te hebben benaderd om uw klacht kenbaar te maken, alvorens u een melding doet middels het NTD-formulier.</p>
        <h2>Hoe verloopt de NTD procedure?</h2>
        <ul>
          <li>Hostingondemand ontvangt een melding via het NTD-formulier. Indien het NTD-formulier voldoende informatie bevat, wordt de procedure gestart. Indien het formulier niet volledig genoeg is ingevuld, wordt het verzoek afgewezen middels een bericht per e-mail. Het is mogelijk het verzoek opnieuw in te dienen.</li>
          <li>Er vindt controle plaats of Hostingondemand de hosting verzorgt voor de desbetreffende website. Indien dit niet het geval is, wordt het verzoek afgewezen middels een bericht per e-mail*.</li>
          <li>Hostingondemand meldt de klacht aan de klant met het verzoek binnen 7 werkdagen te reageren op de klacht. Let op! Hostingondemand maakt de identiteit van de melder kenbaar aan de klant.</li>
        </ul>
        <p>Nadat de 7 werkdagen zijn verstreken, zijn meerdere uitkomsten mogelijk:<br>1. De klant verwijdert het materiaal zelf/ plaatst de website offline binnen 7 werkdagen. Hiermee is de klacht succesvol verholpen. De melder en klant worden hiervan op de hoogte gesteld middels een bericht per e-mail.<br>2. De reactie van de klant is negatief of blijft uit.<br>2a. Hostingondemand bepaalt binnen 7 werkdagen of de inhoud van de website onmiskenbaar onrechtmatig is. Indien dit het geval is, plaatst Websiteondemd de website offline. De klacht is succesvol verholpen. De melder en klant worden hiervan op de hoogte gesteld middels een bericht per e-mail.<br>2b. Indien het voor Hostingondemand niet mogelijk is om onmiskenbare onrechtmatigheid vast te stellen, wordt het Notice-and-Take-Down-verzoek afgewezen. Hostingondemand stelt de melder en klant hiervan op de hoogte middels een bericht per e-mail.</p>
        <p>Let op: Onmiskenbaar onrechtmatige informatie wordt direct ontoegankelijk gemaakt wanneer er sprake is van een (nood)situatie die geen verder uitstel rechtvaardigt.</p>
        <p>*Het is mogelijk het verzoek opnieuw in te dienen indien de melder reeds onsuccesvol de houder van de website en de hostingprovider heeft benaderd en zich om die reden nu richt tot de domeinregistrar Hosting on Demand B.V. . Dit dient de melder aan te geven in het NTD-formulier.</p>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue';

export default {
  name: 'NoticeAndTakeDownProcedure',
  components: {
    Breadcrumbs,
  },
}
</script>